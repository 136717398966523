import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page describes Data Migrators’ policy on responding to data breaches.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Overview</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Purpose</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Scope</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy Compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Exceptions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Non-compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Related Documents</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Definitions and Terms</AnchorLink>
    </AnchorLinks>
    <h2>{`Overview`}</h2>
    <p>{`This policy mandates that any individual who suspects that a theft,
breach or exposure of Data Migrators Protected data or
Data Migrators Sensitive data has occurred must immediately
provide a description of what occurred via e-mail to
`}<a parentName="p" {...{
        "href": "mailto:security@datamigrators.com"
      }}>{`security@datamigrators.com`}</a>{`, calling +61 1300 328264, or through the
use of the help desk reporting web page linked from `}<a parentName="p" {...{
        "href": "https://www.datamigrators.com."
      }}>{`https://www.datamigrators.com.`}</a>{`
This e-mail address, phone number, and web page are monitored by the
Data Migrators’ Information Security Administrator. This team
will investigate all reported thefts, data breaches and exposures to
confirm if a theft, breach or exposure has occurred. If a theft, breach
or exposure has occurred, the Information Security Administrator will
follow the appropriate procedure in place.`}</p>
    <h2>{`Purpose`}</h2>
    <p>{`The purpose of the policy is to establish the goals and the vision for
the breach response process. This policy will clearly define to whom it
applies and under what circumstances, and it will include the definition
of a breach, staff roles and responsibilities, standards and metrics
(e.g., to enable prioritization of the incidents), as well as reporting,
remediation, and feedback mechanisms. The policy shall be well
publicized and made easily available to all personnel whose duties
involve data privacy and security protection.`}</p>
    <p>{`Data Migrators Information Security’s intentions for publishing
a Data Breach Response Policy are to focus significant attention on data
security and data security breaches and how Data Migrators’
established culture of openness, trust and integrity should respond to
such activity. Data Migrators Information Security is committed
to protecting Data Migrators’ employees, partners and the
company from illegal or damaging actions by individuals, either
knowingly or unknowingly.`}</p>
    <h2>{`Scope`}</h2>
    <p>{`This policy applies to all whom collect, access, maintain, distribute,
process, protect, store, use, transmit, dispose of, or otherwise handle
personally identifiable information or Protected Health Information
(PHI) of Data Migrators members. Any agreements with vendors will
contain language similar that protects the fund.`}</p>
    <h2>{`Policy`}</h2>
    <h3>{`Confirmed theft, data breach or exposure of Data Migrators’ Protected or Sensitive data`}</h3>
    <p>{`As soon as a theft, data breach or exposure containing Data Migrators’ Protected data or Data Migrators Sensitive data is
identified, the process of removing all access to that resource will
begin.`}</p>
    <p>{`The Executive Director will chair an incident response team to handle
the breach or exposure.`}</p>
    <p>{`The team will include members from:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`IT Infrastructure`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`IT Applications`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Finance (if applicable)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Communications`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Human Resources`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The affected unit or department that uses the involved system or
output or whose data may have been breached or exposed`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Additional departments based on the data type involved, Additional
individuals as deemed necessary by the Executive Director`}</p>
      </li>
    </ul>
    <h3>{`Confirmed theft, breach or exposure of Data Migrators data`}</h3>
    <p>{`The Executive Director will be notified of the theft, breach or
exposure. IT, along with the designated forensic team, will analyze the
breach or exposure to determine the root cause.`}</p>
    <h3>{`Work with Forensic Investigators`}</h3>
    <p>{`As provided by Data Migrators cyber insurance, the insurer will
need to provide access to forensic investigators and experts that will
determine how the breach or exposure occurred; the types of data
involved; the number of internal/external individuals and/or
organizations impacted; and analyze the breach or exposure to determine
the root cause.`}</p>
    <h3>{`Develop a communication plan`}</h3>
    <p>{`Work with Data Migrators communications, legal and human resource
departments to decide how to communicate the breach to: a) internal
employees, b) the public, and c) those directly affected.`}</p>
    <h3>{`Ownership and Responsibilities`}</h3>
    <p>{`Roles & Responsibilities:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Sponsors are those members of the Data Migrators
community that have primary responsibility for maintaining any
particular information resource. Sponsors may be designated by any
Data Migrators Executive in connection with their administrative
responsibilities, or by the actual sponsorship, collection, development,
or storage of information.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Information Security Administrator is that member of the
Data Migrators community, designated by the Executive Director or
the Director, Information Technology (IT) Infrastructure, who provides
administrative support for the implementation, oversight and
coordination of security procedures and systems with respect to specific
information resources in consultation with the relevant Sponsors.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Users include virtually all members of the Data Migrators
community to the extent they have authorized access to information
resources, and may include staff, trustees, contractors, consultants,
interns, temporary employees and volunteers.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The Incident Response Team shall be chaired by Executive Management
and shall include, but will not be limited to, the following departments
or their representatives: IT-Infrastructure, IT-Application Security;
Communications; Legal; Management; Financial Services, Member Services;
Human Resources.`}</p>
      </li>
    </ul>
    <h2>{`Exceptions`}</h2>
    <p>{`None.`}</p>
    <h2>{`Non-compliance`}</h2>
    <p>{`Any Data Migrators personnel found in violation of this policy
may be subject to disciplinary action, up to and including termination
of employment. Any third party partner company found in violation may
have their network connection terminated.`}</p>
    <h2>{`Related Documents`}</h2>
    <p>{`None.`}</p>
    <h2>{`Definitions and Terms`}</h2>
    <p><strong parentName="p">{`Encryption or encrypted data`}</strong>{` — The most effective way to achieve
data security. To read an encrypted file, you must have access to a
secret key or password that enables you to decrypt it. Unencrypted data
is called plain text;`}</p>
    <p><strong parentName="p">{`Plain text`}</strong>{` — Unencrypted data.`}</p>
    <p><strong parentName="p">{`Hacker`}</strong>{` — A slang term for a computer enthusiast, i.e., a person who
enjoys learning programming languages and computer systems and can often
be considered an expert on the subject(s).`}</p>
    <p><strong parentName="p">{`Protected Health Information (PHI)`}</strong>{` - Under US law is any information
about health status, provision of health care, or payment for health
care that is created or collected by a “Covered Entity” (or a Business
Associate of a Covered Entity), and can be linked to a specific
individual.`}</p>
    <p><strong parentName="p">{`Personally Identifiable Information (PII)`}</strong>{` - Any data that could
potentially identify a specific individual. Any information that can be
used to distinguish one person from another and can be used for
de-anonymizing anonymous data can be considered`}</p>
    <p><strong parentName="p">{`Protected data`}</strong>{` - See PII and PHI`}</p>
    <p><strong parentName="p">{`Information Resource`}</strong>{` - The data and information assets of an
organization, department or unit.`}</p>
    <p><strong parentName="p">{`Safeguards`}</strong>{` - Countermeasures, controls put in place to avoid,
detect, counteract, or minimize security risks to physical property,
information, computer systems, or other assets. Safeguards help to
reduce the risk of damage or loss by stopping, deterring, or slowing
down an attack against an asset.`}</p>
    <p><strong parentName="p">{`Sensitive dat`}</strong>{`a - Data that is encrypted or in plain text and
contains PII or PHI data. See PII and PHI above.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      